import { Link } from "gatsby";
import React from "react";
import Layout from "../../components/layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="flex items-center justify-center py-24 text-center ">
        <div className="content">
          <h1
            className={
              "text-gray-700 text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold mb-8"
            }
          >Thanks for your submission!
          </h1>
          <div>

            Go back to <Link to="/contact/" className="pb-1.5 border-b text-xl border-black">contact</Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
;